import React from "react";
import './style.css';

function NewAward () {
    return (
        <div className="newAward">
            <img src="https://d1wedsacc4lfnu.cloudfront.net/BestOfLufkinAward2023Compressed.png" className="newAwardImage"/>
        </div>
    )
}

export default NewAward;